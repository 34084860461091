import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'

import axios from "axios";

const API = 'https://api.coingecko.com/api/v3/simple/price?ids=kucoin-shares&vs_currencies=usd'

const useGetPriceData = () => {
  const [data, setData] = useState<BigNumber>()


  useEffect(() => {
    const fetchData = async () => {
      try {
       
        const resp  = await axios.get(API);
        const price = new BigNumber(resp.data['kucoin-shares'].usd)
        setData(price)
        
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [])

  return data
}

export default useGetPriceData
