export default {
  cake: {
    56: '0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
    321: '0xdb48aea9cdf4bf01ff240973b345b16f39efacb4',
    97: '',
  },
  masterChef: {
    56: '0xe70E9185F5ea7Ba3C5d63705784D8563017f2E57',
    321: '0x43192adfc45a2ee9680e5991824166ba453d0c49',

    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    321: '0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521',
    
    97: '',
  },
  lottery: {
    56: '',
    97: '',
    321:"",
  },
  lotteryNFT: {
    56: '',
    97: '',
    321:"",
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    321: '0xd790e727a43d1Fb0481b9263d7D5bbBc14c3Cec7',

    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    321: '0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521',

    97: '',
  },
}
